import React from "react";
import { Element } from "react-scroll";

export default function BluePages() {
  return (
    <div className="blue-page">
      {" "}
      <Element name="skillsElement"></Element>
    </div>
  );
}
