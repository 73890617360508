import React from "react";

export default function Intro() {
  return (
    <div className="introPg">
      <div className="sectionHead"> Hi, I'm Nate </div>
      <div>
        And this is a website I designed & developed to organize and showcase
        all the projects I've been working on. I am about to graduate from UCLA
        with a degree in economics and a minor in music industry. I also have a
        deep passion for software engineering and have been self studying for
        nearly 4 years. I'm hard working and extremely dedicated to the things
        I'm passionate about.
      </div>
    </div>
  );
}
